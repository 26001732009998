const districtGeoLocation = [
  {
    "district": "Thiruvananthapuram",
    "latitude": 8.5241391,
    "longitude": 76.9366376
  },
  {
    "district": "Kollam",
    "latitude": 8.8932118,
    "longitude": 76.6141396
  },
  {
    "district": "Pathanamthitta",
    "latitude": 9.2647582,
    "longitude": 76.7870414
  },
  {
    "district": "Idukki",
    "latitude": 9.9188973,
    "longitude": 77.1024902
  },
  {
    "district": "Kottayam",
    "latitude": 9.5915668,
    "longitude": 76.5221531
  },
  {
    "district": "Alappuzha",
    "latitude": 9.4980667,
    "longitude": 76.3388484
  },
  {
    "district": "Ernakulam",
    "latitude": 9.9816358,
    "longitude": 76.2144349
  },
  {
    "district": "Thrissur",
    "latitude": 10.5276416,
    "longitude": 76.2144349
  },
  {
    "district": "Palakkad",
    "latitude": 10.7867303,
    "longitude": 76.6547932
  },
  {
    "district": "Malappuram",
    "latitude": 11.0509762,
    "longitude": 76.0710967
  },
  {
    "district": "Kozhikode",
    "latitude": 11.2587531,
    "longitude": 75.78041
  },
  {
    "district": "Wayanad",
    "latitude": 11.6853575,
    "longitude": 76.1319953
  },
  {
    "district": "Kannur",
    "latitude": 11.8744775,
    "longitude": 75.3703662
  },
  {
    "district": "Kasaragod",
    "latitude": 12.4995966,
    "longitude": 74.9869276
  }
];
export default districtGeoLocation;
