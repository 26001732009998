import React, { useState, useEffect } from "react";
import {
  Circle,
  Map,
  Marker,
  Popup,
  // Tooltip,
  TileLayer,
} from "react-leaflet";
import { readRemoteFile } from "react-papaparse";
import geoLocation from "../data/geoLocation.js";
import districtGeoLocation from "../data/districtGeoLocation.js";
import testCenters from "../data/testCenters.js";
import classNames from "classnames/bind";
const cx = classNames.bind(require("./map.module.css"));

let center = [9.5915668, 76.5221531];
const papaparseOptions = {
  quotes:false,
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

const PopupLineItem = ({ type, count, legend }) => {
  return (
    <>
      <div className={cx(["popup-legend", "legend-" + legend])}></div>
      <div className={cx("count-type")}>{type}</div>
      <div className={cx("counts")}>
        {count !== undefined && count !== null
          ? count.toLocaleString(navigator.language, {
              maximumFractionDigits: 2,
            })
          : ""}
      </div>
    </>
  );
};

export default function MapContainer(props) {
  const {
    onStateWiseDataGetSuccess,
    onDistrictWiseDataGetSuccess,
    viewTestCenters,
    selectedLocCoordinate,
  } = props;

  if (selectedLocCoordinate && selectedLocCoordinate.length) {
    center = [
      selectedLocCoordinate[0].latitude,
      selectedLocCoordinate[0].longitude,
    ];
  }
  const [indiaData, setIndiaData] = useState(null);

  const [stateData, setStateData] = useState(null);
  const [countrySummary, setCountrySummary] = useState(null);

  const [districtData, setDistrictData] = useState(null);

  const [internationalData, setInternationalData] = useState(null);
  const [countryStats, setCountryStats] = useState(null);
  const [worldStats, setWorldStats] = useState(null);

  // const [viewTestCenters, setViewTestCenters] = useState(false);
  const [showInfoHead, setShowInfoHead] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const parseInternationalData = (res) => {
    console.log("Setting International Data");
    setInternationalData(res.data);
    setWorldStats(
        res.data.reduce((a, b) => ({
        confirmed: a.confirmed + b.confirmed,
        deaths: a.deaths + b.deaths,
        recovered: a.recovered + b.recovered,
      }))
    );
  };
  useEffect(() => {
    if (countrySummary)
      if (indiaData?.countryData)
        if (
          countrySummary.confirmedCasesIndian +
            countrySummary.confirmedCasesForeign >
          indiaData.countryData.total
        )
          setIndiaData(null);
  }, [stateData, indiaData]);
  console.log(worldStats);

  useEffect(() => {
    console.log("Fetching Data");
    fetch("https://exec.clay.run/kunksed/mohfw-covid")
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("Received Response")
          setIndiaData(result);
        },
        (error) => {
          // console.log("Error Response")
        }
      );
    fetch("https://keralastats.coronasafe.live/latest.json")
      .then(result=>result.json()
      .then(
        (data) => {
          onDistrictWiseDataGetSuccess
            ? onDistrictWiseDataGetSuccess(data.summary)
            : (() => {})();
          setDistrictData(data.summary);
        },
        (error) => {
          console.log("Error Response");
        }
      ));

    fetch("https://api.rootnet.in/covid19-in/stats/latest")
      .then((res) => res.json())
      .then(
        (result) => {
          onStateWiseDataGetSuccess
            ? onStateWiseDataGetSuccess(result.data)
            : (() => {})();
          setStateData(
            Object.assign(
              {},
              ...result.data.regional.map(
                ({
                  loc,
                  confirmedCasesIndian,
                  confirmedCasesForeign,
                  deaths,
                  discharged,
                }) => ({
                  [loc]: {
                    confirmedCasesIndian,
                    confirmedCasesForeign,
                    deaths,
                    discharged,
                  },
                })
              )
            )
          );

          setCountrySummary(result.data.summary);
        },
        (error) => {
          console.log("Error Response");
        }
      );

    const tryYesterday = (date) => {
      date.setDate(date.getDate() - 1);
      const formattedDate =
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        date.getFullYear();
      // console.log(formattedDate);
      readRemoteFile(
        "https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_daily_reports/" +
          // formattedDate +
          "10-06-2020" +
          ".csv",
        {
          ...papaparseOptions,
          complete: parseInternationalData,
          error: () => tryYesterday(date),
        }
      );
    };
    const date = new Date();
    const formattedDate =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "-" +
      date.getFullYear();
    // console.log(formattedDate);
    readRemoteFile(
      "https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_daily_reports/" +
        // formattedDate +
        "10-06-2020" +
        ".csv",
      {
        ...papaparseOptions,
        complete: parseInternationalData,
        error: () => tryYesterday(date),
      }
    );
  }, []);
  console.log(viewTestCenters);

  return (
    <div className={"map-container"}>
      <Map center={center} zoom={7}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        {indiaData && indiaData.stateData
          ? geoLocation.map((location) => {
              // console.log(
              //   location.state +
              //     "|" +
              //     JSON.stringify(indiaData.stateData[location.state])
              // );
              const locationData = indiaData.stateData[location.state];
              if (locationData.cases === 0 || location.state === "Kerala")
                return null;
              return (
                <Circle
                  key={location.state}
                  center={[location.latitude, location.longitude]}
                  fillColor="red"
                  radius={locationData.cases+10000}
                  onMouseOver={(e) => {
                    firstLoad && setFirstLoad(false);
                    e.target.openPopup();
                  }}
                >
                  <Popup>
                    <h3>{location.state}</h3>
                    <div className={cx("popup-line-wrap")}>
                      <PopupLineItem
                        legend="cases"
                        type="Cases"
                        count={locationData.cases}
                      />
                      <PopupLineItem
                        legend="cured"
                        type="Cured/Discharged"
                        count={locationData.cured_discharged}
                      />
                      <PopupLineItem
                        legend="death"
                        type="Deaths"
                        count={locationData.Deaths}
                      />
                      <hr />
                      Helpline: {locationData.helpline}
                    </div>
                  </Popup>
                </Circle>
              );
            })
          : stateData &&
            geoLocation.map((location) => {
              // console.log(location.state + "|" + JSON.stringify(indiaData.stateData[location.state]))
              const locationData = stateData[location.state];
              if (
                locationData === undefined ||
                (locationData.confirmedCasesIndian === 0 &&
                  locationData.confirmedCasesForeign === 0) ||
                location.state === "Kerala"
              )
                return null;
              return (
                <Circle
                  key={location.state}
                  center={[location.latitude, location.longitude]}
                  fillColor="red"
                  radius={Math.max(
                    (locationData.confirmedCasesIndian +
                      locationData.confirmedCasesForeign) *
                      0.15,
                    40000
                  )}
                  onMouseOver={(e) => {
                    firstLoad && setFirstLoad(false);
                    e.target.openPopup();
                  }}
                >
                  <Popup>
                    <h3>{location.state}</h3>
                    <div className={cx("popup-line-wrap")}>
                      <PopupLineItem
                        legend="cases"
                        type="Cases"
                        count={
                          locationData.confirmedCasesIndian +
                          locationData.confirmedCasesForeign
                        }
                      />
                      <PopupLineItem
                        legend="cured"
                        type="Cured/Discharged"
                        count={locationData.discharged}
                      />
                      <PopupLineItem
                        legend="death"
                        type="Deaths"
                        count={locationData.deaths}
                      />
                    </div>
                  </Popup>
                </Circle>
              );
            })}
        {districtData &&
          districtGeoLocation.map((location) => {
            // console.log(location.state + "|" + JSON.stringify(indiaData.stateData[location.state]))
            console.log(location);
            const locationData = districtData[location.district];
            if (
              locationData === undefined ||
              locationData.confirmed === 0
            )
              return null;
            return (
              <Circle
                key={location.district}
                center={[location.latitude, location.longitude]}
                fillColor="red"
                radius={Math.min(locationData.confirmed,20000)}
                onMouseOver={(e) => {
                  firstLoad && setFirstLoad(false);
                  e.target.openPopup();
                }}
              >
                {location.state !== "Kerala" && (
                  // firstLoad ?
                  // <Tooltip permanent>
                  // <h3>{location.state}</h3><br/>
                  // Cases: {locationData.cases},<br/>
                  // Cured/Discharged: {locationData.cured_discharged},<br/>
                  // Deaths: {locationData.deaths},<br/>
                  // Helpline: {locationData.helpline}</Tooltip>
                  // :
                  <Popup>
                    <h3 style={{ margin: "0px" }}>{location.district}</h3>
                    Kerala
                    <br />
                    <div className={cx("popup-line-wrap")}>
                      <PopupLineItem
                        legend="observation"
                        type="Observation"
                        count={locationData.total_obs}
                      />
                      <PopupLineItem
                        legend="hospitalized"
                        type="Hospitalized"
                        count={locationData.hospital_today}
                      />
                      <PopupLineItem
                        legend="home-isolation"
                        type="Home Isolation"
                        count={locationData.home_obs}
                      />
                      <PopupLineItem
                        legend="cases"
                        type="Cases"
                        count={locationData.active}
                      />
                      <PopupLineItem
                        legend="cured"
                        type="Cured/Discharged"
                        count={locationData.recovered}
                      />
                      <PopupLineItem
                        legend="death"
                        type="Deaths"
                        count={locationData.deceased}
                      />
                    </div>
                  </Popup>
                )}
              </Circle>
            );
          })}
        {/* {Array.isArray(internationalData) &&
          internationalData.map((location,i) => {
            console.log(location);
            if (location.country_region === "India") {
              if (countryStats === null) setCountryStats(location);
              return null;
            }
            return (
              <Circle
                key={
                 i
                }
                center={[location.lat, location.long_ ]}
                fillColor="red"
                radius={15000 + location.confirmed * 20}
                onMouseOver={e => {
                  e.target.openPopup();
                }}
              >
                <Popup>
                  <h3>
                    {location.province_state
                      ? location.province_state
                      : location.country_region}
                  </h3>
                  {location.province_state && (
                    <span>
                      {location.country_region}
                      <br />
                    </span>
                  )}
                  <div className={cx("popup-line-wrap")}>
                    <PopupLineItem
                      legend="cases"
                      type="Cases"
                      count={location.confirmed}
                    />
                    <PopupLineItem
                      legend="cured"
                      type="Cured/Discharged"
                      count={location.recovered}
                    />
                    <PopupLineItem
                      legend="death"
                      type="Deaths"
                      count={location.deaths}
                    />
                  </div>
                  <hr />
                  Last Update: {location.last_update}
                  <br />
                </Popup>
              </Circle>
            );
          })} */}
        {viewTestCenters &&
          testCenters.map((testCenter) => {
            return (
              <Marker
                key={testCenter.institution}
                position={[testCenter.latitude, testCenter.longitude]}
                onMouseOver={(e) => {
                  e.target.openPopup();
                }}
              >
                <Popup>
                  <h3>{testCenter.institution}</h3>
                  <a
                    href={
                      "https://www.google.com/maps/search/?api=1&query=" +
                      testCenter.institution +
                      "&query_place_id=" +
                      testCenter.place_id
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open in Maps
                  </a>
                </Popup>
              </Marker>
            );
          })}
      </Map>
    </div>
  );
}
